import React, { useEffect, useState } from "react";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
} from "../../components/Component";
import {
  Badge,
  Card,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import ImageContainer from "../../components/partials/gallery/GalleryImage";
import Content from "../../layout/content/Content";
import classnames from "classnames";
import placeHolderImg from "../../assets/images/placeHolderImg.png";
import ReactPlayer from "react-player";
import "../../css/style.css";
import thumbnail from "../../images/ThumbnailImage.jpg";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import audioFile from "../../images/audiofile1.mp3";

const Portfolio = ({ companyData, isLoading }) => {
  const [activeTab, setActiveTab] = useState("1");
  const [activeVideo, setActiveVideo] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryChk, setSelectedCategoryChk] = useState("All");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [videosData, setVideosData] = useState([]);
  const [videoOpen, setVideoOpen] = useState(false);
  const [videoValue, setVideoValue] = useState(null);
  const [companyDataLoading, setCompanyDataLoading] = useState(false);

  const togglevid = () => setVideoOpen(!videoOpen);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const categories = companyData?.images ? Object.keys(companyData.images) : [];
  const filteredImages = companyData?.images ? companyData?.images : {};
  const handleCategorySelect = (category) => {
    setSelectedCategory(category === "All" ? null : category);
    setSelectedCategoryChk(category);
  };
  const handleVideoClick = (index) => {
    setActiveVideo(index === activeVideo ? null : index);
  };

  const closeVideoModal = () => {
    setActiveVideo(null);
  };

  useEffect(() => {
    const uploadVideo = companyData?.videos;
    if (uploadVideo) {
      setVideosData(uploadVideo);
    } else {
      setVideosData([]);
    }
  }, [companyData]);

  return (
    <div id="portfolio">
      <Block size="lg">
        <PreviewCard>
          <BlockHead size="sm" className="my-2 mx-2">
            <BlockBetween className="g-3 justify-center">
              <BlockHeadContent>
                <BlockTitle page>
                  <h3 className="text-center">Portfolio</h3>
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Nav tabs className="mt-n3 justify-center">
            <NavItem>
              <NavLink
                tag="a"
                href="#tab"
                className={classnames({ active: activeTab === "1" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("1");
                }}
              >
                Mixes
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag="a"
                href="#tab"
                className={classnames({ active: activeTab === "2" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("2");
                }}
              >
                Videos
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag="a"
                href="#tab"
                className={classnames({ active: activeTab === "3" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("3");
                }}
              >
                Images
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab} className="p-0 m-0">
            <TabPane tabId="1">
              <Content>
                <Row className="g-1">
                  <Col lg={12}></Col>
                  <Col lg={3}>
                    <div class="cardaudio ">
                      <div class="topaudio ">
                        <div class="pfp">
                          <div class="playingaudio ">
                            <div class="greenlineaudio line-1audio "></div>
                            <div class="greenlineaudio  line-2audio "></div>
                            <div class="greenlineaudio  line-3audio "></div>
                            <div class="greenlineaudio  line-4audio "></div>
                            <div class="greenlineaudio  line-5audio "></div>
                          </div>
                        </div>
                        <div class="textsaudio">
                          <p class="title-1audio ">Audio - 2</p>
                        </div>
                      </div>
                      <div className="mt-2">
                        {" "}
                        <ReactPlayer url={audioFile} controls={true} width="100%" height="30px" />
                      </div>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div class="cardaudio ">
                      <div class="topaudio ">
                        <div class="pfp">
                          <div class="playingaudio ">
                            <div class="greenlineaudio line-1audio "></div>
                            <div class="greenlineaudio  line-2audio "></div>
                            <div class="greenlineaudio  line-3audio "></div>
                            <div class="greenlineaudio  line-4audio "></div>
                            <div class="greenlineaudio  line-5audio "></div>
                          </div>
                        </div>
                        <div class="textsaudio">
                          <p class="title-1audio ">Audio - 3</p>
                        </div>
                      </div>
                      <div className="mt-2">
                        {" "}
                        <ReactPlayer url={audioFile} controls={true} width="100%" height="30px" />
                      </div>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div class="cardaudio ">
                      <div class="topaudio ">
                        <div class="pfp">
                          <div class="playingaudio ">
                            <div class="greenlineaudio line-1audio "></div>
                            <div class="greenlineaudio  line-2audio "></div>
                            <div class="greenlineaudio  line-3audio "></div>
                            <div class="greenlineaudio  line-4audio "></div>
                            <div class="greenlineaudio  line-5audio "></div>
                          </div>
                        </div>
                        <div class="textsaudio">
                          <p class="title-1audio ">Audio - 4</p>
                        </div>
                      </div>
                      <div className="mt-2">
                        {" "}
                        <ReactPlayer url={audioFile} controls={true} width="100%" height="30px" />
                      </div>
                    </div>
                  </Col>{" "}
                  <Col lg={3}>
                    <div class="cardaudio ">
                      <div class="topaudio ">
                        <div class="pfp">
                          <div class="playingaudio ">
                            <div class="greenlineaudio line-1audio "></div>
                            <div class="greenlineaudio  line-2audio "></div>
                            <div class="greenlineaudio  line-3audio "></div>
                            <div class="greenlineaudio  line-4audio "></div>
                            <div class="greenlineaudio  line-5audio "></div>
                          </div>
                        </div>
                        <div class="textsaudio">
                          <p class="title-1audio ">Audio - 5</p>
                        </div>
                      </div>
                      <div className="mt-2">
                        {" "}
                        <ReactPlayer url={audioFile} controls={true} width="100%" height="30px" />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Content>
            </TabPane>
            <TabPane tabId="2">
              <Content>
                <Block>
                  <Row className="g-3 mt-1">
                    {videosData.map((video, index) => (
                      <Col sm={6} lg={4} xxl={3} key={index} className="justify-center">
                        <Card
                          className="card-bordered gallery align-center w-85 border-0"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="video justify-center">
                            <div className="player-wrapper">
                              <img className="video-poster w-100" src={thumbnail} alt="DJ Video" />
                              <div
                                className="video-play popup-video start-0 video-modal"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  togglevid();
                                  setVideoValue(video?.path);
                                }}
                              >
                                <div class="vidbutton">
                                  <svg
                                    viewBox="0 0 448 512"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    width="20px"
                                  >
                                    <path
                                      d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    ))}
                    {videosData.length === 0 && (
                      <div className="d-flex justify-content-center align-items-center">
                        <p className="text-muted fs-2 fw-light my-2">No videos available at the moment.</p>
                      </div>
                    )}
                  </Row>
                </Block>
              </Content>
            </TabPane>
            <TabPane tabId="3">
              <Content>
                <Block>
                  {companyData?.images ? (
                    <div>
                      {companyData?.images.length === 0 ? (
                        ""
                      ) : (
                        <div>
                          <span onClick={() => handleCategorySelect("All")}>
                            <Badge
                              color="outline-primary"
                              className={
                                selectedCategoryChk == "All"
                                  ? "fs-5 me-1 p-1 rounded-5 px-4 text-primary"
                                  : "fs-5 me-1 p-1 rounded-5 px-4 btn-dim btn-outline-light"
                              }
                              style={{ cursor: "pointer" }}
                            >
                              All
                            </Badge>
                          </span>
                          {categories.map((category, index) => (
                            <span key={index} onClick={() => handleCategorySelect(category)}>
                              <Badge
                                color="outline-primary"
                                className={
                                  selectedCategoryChk == category
                                    ? "fs-5 me-1 p-1 rounded-5 px-4 text-primary"
                                    : "fs-5 me-1 p-1 rounded-5 px-4 btn-dim btn-outline-light"
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {category}
                              </Badge>
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  <Row className="g-3 mt-3">
                    {(selectedCategory ? filteredImages[selectedCategory] : Object.values(filteredImages))
                      .flat()
                      .map((imageUrl, index) => (
                        <Col key={index} sm={6} lg={3} xxl={3} className="m-0 p-0">
                          <Card className="gallery">
                            <ImageContainer img={imageUrl?.url} />
                          </Card>
                        </Col>
                      ))}
                    {Object.keys(filteredImages).length === 0 && (
                      <div className="d-flex justify-content-center align-items-center w-100">
                        <p className="text-muted fs-2 fw-light my-2">No images available at the moment</p>
                      </div>
                    )}
                  </Row>
                </Block>
              </Content>
            </TabPane>
          </TabContent>
        </PreviewCard>
      </Block>
      <Modal size="lg" isOpen={videoOpen} toggle={togglevid}>
        <ModalHeader
          toggle={togglevid}
          close={
            <button className="close" onClick={togglevid}>
              <Icon name="cross" />
            </button>
          }
        >
          Video Preview
        </ModalHeader>
        <ModalBody className="p-0" style={{ height: "500px" }}>
          {isLoading ? (
            <div>
              <ShimmerSimpleGallery card imageHeight={100} />
            </div>
          ) : (
            <ReactPlayer controls url={videoValue} className="react-player" width="100%" height="100%" />
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Portfolio;
