import React from "react";
import { Block, Icon, TooltipComponent } from "../../components/Component";
import { Card, Nav, NavItem } from "reactstrap";
import { Link } from "react-scroll";

const SubMenu = () => {
  return (
    <>
      <Block size="lg" className=" ">
        <Card className="border-0 ">
          <Nav tabs style={{ cursor: "pointer" }} className="border-0 nk-sticky-toolbar d-flex flex-column ">

            <NavItem className="p-0" id="Home">
              <Link 
                activeClass="bg-primary text-white" 
                to="home" 
                spy={true} 
                className="fw-medium fs-3 text-black">
                <Icon name="home" />
              </Link>
              <TooltipComponent id="Home" text="Home Section" direction="left" />
            </NavItem>

            <NavItem className="p-0" id="Summary">
              <Link
                activeClass="bg-primary text-white"
                to="Professionalsummery"
                spy={true}
                className="fw-medium fs-3 text-black"
              >
                <Icon name="card-view" />
              </Link>
              <TooltipComponent id="Summary" text="Professional Summary" direction="left" />
            </NavItem>

            <NavItem className="p-0" id="Portfolio">
              <Link
                activeClass="bg-primary text-white"
                to="portfolio"
                spy={true}
                className="fw-medium fs-3 text-black"
                style={{ cursor: "pointer" }}
              >
                <Icon name="note-add-c" />
              </Link>
              <TooltipComponent id="Portfolio" text="Portfolio" direction="left" />
            </NavItem>

            <NavItem className="p-0" id="Review">
              <Link
                activeClass="bg-primary text-white"
                to="review"
                spy={true}
                className="fw-medium fs-3 text-black"
                style={{ cursor: "pointer" }}
              >
                <Icon name="notice" />
              </Link>
              <TooltipComponent id="Review" text="Reviews" direction="left" />
            </NavItem>
          </Nav>
        </Card>
      </Block>
    </>
  );
};

export default SubMenu;
