import React from "react";
import Head from "../../layout/head/Head";
import HeroSection from "./HeroSection";
import TopDjs from "./TopDjs";
import { Card, Col, Row } from "reactstrap";
import FAQ from "../Individual DJ Pages/FAQ";
import DjSection from "../DJ Section/DjSection";

const HomePage = () => {
  return (
    <>
      <Head title="Default Dashboard" />
      <section>
        <HeroSection />
        <TopDjs />
        <Row className="g-0">
          <Col xxl="12" className="m-0">
            <Card>
              <DjSection />
            </Card>
            <Card className="card-bordered mt-0">
              <FAQ />
            </Card>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default HomePage;
