import React from "react";
import "../../css/style.css";
import VideoSlider from "./VideoSlider";
import ServiceCards from "./ServiceCards";
import services2 from "../../images/services2.jpg";

const AboutSection = () => {
  return (
    <>
      <div style={{ height: "91vh" }}>
        <VideoSlider />
      </div>
      <div
        className="align-center justify-content-center d-flex h-350px"
        style={{
          backgroundImage: `url(${services2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <span
          className="btn-primary text-white rounded-3 fw-medium px-4 py-1 w-350px text-center align-center justify-center"
          style={{ fontSize: "25px", height: "70px" }}
        >
          I Party DJ MIX
        </span>
      </div>
      {/* Service section  */}
      <div>
        <ServiceCards />
      </div>
    </>
  );
};

export default AboutSection;
