import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  UncontrolledDropdown,
} from "reactstrap";
import ListView from "./ListView";
import ImagesView from "./ImagesView";
import Icon from "../../components/icon/Icon";
import MapView from "./MapView";
import PaginationComponent from "../../components/pagination/Pagination";

const ProfileList = ({
  fetchedCompaniesList,
  currentPage,
  setCurrentPage,
  companiesListLoading,
  handlePageChange,
  profilesPerPage,
  dJCompany,
  setMapView,
  // sortValue,
  // setSortValue,
}) => {
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [view, setView] = useState("list");

  useEffect(() => {
    if (fetchedCompaniesList?.data) {
      const parsedData = fetchedCompaniesList.data.map((profile) => ({
        ...profile,
        images: profile.images ? JSON.parse(profile.images) : {},
      }));
      setData(parsedData);
      setTotalPages(Math.ceil(fetchedCompaniesList.total / profilesPerPage));
      setTotalCount(fetchedCompaniesList.total);
    }
  }, [fetchedCompaniesList, profilesPerPage]);

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [totalPages, currentPage, setCurrentPage]);

  const handleViewChange = (viewType) => {
    setView(viewType);
    setCurrentPage(1);
    setMapView(viewType);
  };
  const handleViewMap = (viewType) => {
    setView(viewType);
    setMapView(viewType);
  };
  const handleToggleSort = (value) => {
    // setSortValue(value);
  };
  return (
    <div className="px-0">
      <div
        className={view === "map" ? "d-flex justify-content-between my-2 mx-1" : "d-flex justify-content-between mx-1"}
      >
        {view === "map" ? (
          <h5 className="align-center m-0 mx-4">Select Nearby DJ's</h5>
        ) : (
          <h5 className="align-center m-0">
            {totalCount || 0} {totalCount <= 1 ? "Result" : "Results"}
          </h5>
        )}
        <div className="d-flex gx-2">
          {/* <div className="gx-2">
            <Label>Sort By</Label>
            <UncontrolledDropdown>
              <div className="btn-group">
                <Button className="border bg-white text-body">
                  // {/* {sortValue === "desc" ? "Price High to Low" : "Price Low To High"} 
                </Button>
                <DropdownToggle className="dropdown-toggle-split" color="primary">
                  <Icon name="chevron-down"></Icon>
                </DropdownToggle>
              </div>
              <DropdownMenu>
                <ul className="link-list-opt">
                  <li>
                    <DropdownItem tag="a" href="#links" onClick={() => handleToggleSort("asc")}>
                      <span>Price Low To High</span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem tag="a" href="#links" onClick={() => handleToggleSort("desc")}>
                      <span>Price High to Low</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div> */}
          <div>
            <ButtonGroup className="me-4">
              <Button
                color={view === "list" ? "primary" : "light"}
                className={`border-1 border-success-subtle ${view === "list" ? "active" : ""}`}
                onClick={() => handleViewChange("list")}
              >
                <Icon name="list-round" className="me-1 fs-4" />
                List
              </Button>
              <Button
                color={view === "images" ? "primary" : "light"}
                className={`border-1 border-success-subtle ${view === "images" ? "active" : ""}`}
                onClick={() => handleViewChange("images")}
              >
                <Icon name="cards" className="me-1 fs-4" />
                Images
              </Button>
              <Button
                color={view === "map" ? "primary" : "light"}
                className={`border-1 border-success-subtle ${view === "map" ? "active" : ""}`}
                onClick={() => handleViewChange("map")}
              >
                <Icon name="map-pin" className="me-1 fs-4" />
                Map
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </div>

      {view === "map" ? (
        <MapView profiles={data} city={data?.[0]?.city} />
      ) : view === "images" ? (
        <div className="mb-3 p-2 rounded">
          {totalCount > 0 ? (
            <div>
              <ImagesView profiles={data} companiesListLoading={companiesListLoading} />
              {totalPages > 0 && (
                <div className="mt-3">
                  {fetchedCompaniesList?.total < 8 ? (
                    <></>
                  ) : (
                    <PaginationComponent
                      itemPerPage={profilesPerPage}
                      totalItems={totalCount}
                      paginate={handlePageChange}
                      currentPage={currentPage}
                    />
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center mt-5 pt-5">
              <div className="text-center pt-5 mt-5">
                <h5>No profiles matched your search.</h5>
                <p>Try searching with different criteria or explore the following options</p>
                <ButtonGroup>
                  <Button color="primary" onClick={() => handleViewMap("map")}>
                    Search Nearby
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          )}
        </div>
      ) : view === "list" ? (
        <div className="mb-3 p-2 rounded">
          {totalCount > 0 ? (
            <>
              <ListView profiles={data} companiesListLoading={companiesListLoading} dJCompany={dJCompany} />
              {totalPages > 0 && (
                <div className="mt-3">
                  {fetchedCompaniesList?.total < 8 ? (
                    <></>
                  ) : (
                    <PaginationComponent
                      itemPerPage={profilesPerPage}
                      totalItems={totalCount}
                      paginate={handlePageChange}
                      currentPage={currentPage}
                    />
                  )}
                </div>
              )}
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center mt-5 pt-5">
              <div className="text-center pt-5 mt-5">
                <h5>No profiles matched your search.</h5>
                <p>Try searching with different criteria or explore the following options</p>
                <ButtonGroup>
                  <Button color="primary" onClick={() => handleViewMap("map")}>
                    Search Nearby
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ProfileList;
