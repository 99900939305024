import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";
import FileManager from "../pages/Home Page/Homepage";
import FileManagerFiles from "../pages/Home Page/Homepage";
import FileManagerShared from "../pages/Home Page/Homepage";
import FileManagerStarred from "../pages/Home Page/Homepage";
import FileManagerRecovery from "../pages/Home Page/Homepage";
import FileManagerSettings from "../pages/Home Page/Homepage";
import LayoutApp from "../layout/Index-app";
import Layout from "../layout/Index";
import LayoutNoSidebar from "../layout/Index-nosidebar";
import UserProfileRegular from "../pages/prebuilt/user-manage/UserProfileRegular";
import UserProfileSetting from "../pages/prebuilt/user-manage/UserProfileSetting";
import UserProfileNotification from "../pages/prebuilt/user-manage/UserProfileNotification";
import Homepage from "../pages/Home Page/Homepage";
import DjProfiles from "../pages/DjProfiles/ProfilesDj";
import SelectDJ from "../pages/Individual DJ Pages/SelectDJ";
import DjTimeLine from "../pages/DJ Section/DjTimeLine";
import DJBookinEvent from "../pages/Individual DJ Pages/DJBookinEvent";
import AboutSection from "../pages/About/AboutSection";
import Contact from "../pages/About/Contact";
// import Slidercustom from "../pages/Individual DJ Pages/Slidercustom";

const Pages = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route
        path={`${process.env.PUBLIC_URL}`}
        element={<Layout app={{ icon: "dashboard", theme: "bg-purple-dim", text: "Dashboard" }} />}
      >
        <Route index element={<Homepage />}></Route>
        <Route path="home" element={<Homepage />}></Route>
        <Route path="about" element={<AboutSection />}></Route>
        <Route path="contact" element={<Contact />}></Route>
        <Route path="/dj-profiles/:pageNumber" element={<DjProfiles />}></Route>
        <Route path="/dj-profiles/specificDj/:id" element={<SelectDJ />}></Route>
        <Route path="/dj-section" element={<DjTimeLine />}></Route>
        <Route path="/dj-profiles/specificDj/:id/dj-bookevent" element={<DJBookinEvent />}></Route>
        {/* <Route path="/dj-profiles/slidercustom" element={<Slidercustom />}></Route> */}

        <Route>
          <Route path="user-profile-notification" element={<UserProfileNotification />}></Route>
          <Route path="user-profile-regular" element={<UserProfileRegular />}></Route>
          {/* <Route path="user-profile-activity" element={<UserProfileActivity />}></Route> */}
          <Route path="user-profile-setting" element={<UserProfileSetting />}></Route>
        </Route>
      </Route>

      <Route path={`${process.env.PUBLIC_URL}`}>
        <Route element={<LayoutApp app={{ icon: "folder", theme: "bg-orange-dim", text: "File Manager" }} />}>
          <Route path="app-file-manager">
            <Route index element={<FileManager />}></Route>
            <Route path="files" element={<FileManagerFiles />}></Route>
            <Route path="starred" element={<FileManagerStarred />}></Route>
            <Route path="shared" element={<FileManagerShared />}></Route>
            <Route path="recovery" element={<FileManagerRecovery />}></Route>
            <Route path="settings" element={<FileManagerSettings />}></Route>
          </Route>
        </Route>
      </Route>

      <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
        <Route path="errors">
          <Route path="404-modern" element={<Error404Modern />}></Route>
          <Route path="404-classic" element={<Error404Classic />}></Route>
          <Route path="504-modern" element={<Error504Modern />}></Route>
          <Route path="504-classic" element={<Error504Classic />}></Route>
        </Route>
        <Route path="*" element={<Error404Modern />}></Route>
      </Route>
    </Routes>
  );
};
export default Pages;
