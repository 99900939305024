import React, { useEffect, useState } from "react";
import { Block } from "../../components/Component";
import { Button, Col, Row, Spinner } from "reactstrap";
import HeroSectionImage from "../../images/contact_us.jpg";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import services2 from "../../images/services2.jpg";
import "../../css/style.css";

export const Required = () => {
  return (
    <>
      <span style={{ color: "red" }}> *</span>
    </>
  );
};

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const blockNumbers = (val) => {};
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    event_type: "",
    event_name: "",
    event_package: "",
    event_message: "",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset(formData);
  }, [formData]);

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      phone_number: "",
      event_type: "",
      event_name: "",
      event_package: "",
      event_message: "",
    });
    reset({});
  };

  const onFormSubmit = async (form) => {
    toast.success("Submited Successfully");
    resetForm();
  };
  return (
    <Block className="">
      <div
        className="d-flex justify-content-center align-center h-175px"
        style={{
          backgroundImage: `url(${services2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h3 className="ff-alt fw-semibold text-capitalize text-center text-primary">Contact Us</h3>
      </div>
      <div className="align-center justify-center w-100" id="contact">
        <Row className="g-2 m-0 w-100">
          <Col md={12} lg={6}>
            <div className="h-100">
              <div className="d-flex justify-content-center rounded-3 h-100">
                <img src={HeroSectionImage} alt="" className="rounded-3" />
              </div>
            </div>
          </Col>
          <Col md={12} lg={6} className="contactfrm leaflet-bar">
            <div className="mt-4">
              <form noValidate onSubmit={handleSubmit(onFormSubmit)} className="">
                <Row className="g-0">
                  <Col lg={6} className="justify-center">
                    <div className="form-group w-75">
                      <div className="form-control-wrap my-2">
                        <label htmlFor="event_message">
                          Full Name <Required />
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="name"
                          placeholder="Enter Full Name"
                          {...register("name", {
                            required: "This field is required",
                            pattern: {
                              value: /^[A-Za-z\s]+$/,
                              message: "Only alphabets and spaces are allowed",
                            },
                            minLength: {
                              value: 3,
                              message: "Please enter at least 3 characters",
                            },
                          })}
                          value={formData.name}
                          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                          maxLength={30}
                        />
                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} className="justify-center">
                    <div className="form-group w-75">
                      <div className="form-control-wrap my-2">
                        <label htmlFor="event_message">
                          Email <Required />
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="email"
                          placeholder="Enter Email"
                          {...register("email", {
                            required: "This is required",
                            pattern: {
                              value:
                                // eslint-disable-next-line
                                /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                              message: "Enter a valid email address",
                            },
                            maxLength: { value: 255, message: "Email must not exceed 255 characters" },
                          })}
                          value={formData.email}
                          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        />
                        {errors.email && <span className="invalid">{errors.email.message}</span>}
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} className="justify-center">
                    <div className="form-group w-75">
                      <div className="form-control-wrap my-2">
                        <label htmlFor="event_message">
                          Contact Number <Required />
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          id="phone_number"
                          onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                          placeholder="Enter Contact Number"
                          {...register("phone_number", {
                            required: "This is required",
                            maxLength: {
                              value: 15,
                              message: "You rich maximum contact number limit!",
                            },
                          })}
                          value={formData.phone_number}
                          onChange={(e) => {
                            if (e.target.value.length <= 15) {
                              setFormData({ ...formData, phone_number: e.target.value });
                            } else {
                              blockNumbers();
                            }
                          }}
                        />
                        {errors.phone_number && <span className="invalid">{errors.phone_number.message}</span>}
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} className="justify-center">
                    <div className="form-group w-90 px-2">
                      <div className="form-control-wrap my-2">
                        <label htmlFor="event_message">How can we help you?</label>
                        <textarea
                          className="form-control h-100px"
                          type="text"
                          id="event_message"
                          placeholder="Enter Event Message"
                          {...register("event_message", {
                            pattern: {
                              message: "Only alphabets and spaces are allowed",
                            },
                            minLength: {
                              value: 3,
                              message: "Please enter at least 3 characters",
                            },
                          })}
                          maxLength={500}
                          value={formData.event_message}
                          onChange={(e) => setFormData({ ...formData, event_message: e.target.value })}
                        />
                        {errors.event_message && <span className="invalid">{errors.event_message.message}</span>}
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="justify-center my-2">
                  {loading ? (
                    <Button disabled color="primary" className="w-50">
                      <Spinner size="sm" />
                      <span> Loading... </span>
                    </Button>
                  ) : (
                    <Button color="primary" className="justify-center rounded w-50" type="submit">
                      Submit
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </Block>
  );
};

export default Contact;
