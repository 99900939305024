import React from "react";
import { Button, Col, Row } from "reactstrap";
import { useNavigate } from "react-router";
import PlaceHolderImage from "../../images/PlaceHolderImg2.jpg";

const ListView = ({ profiles, companiesListLoading }) => {
  const navigate = useNavigate();
  if (companiesListLoading)
    return (
      <div style={{ height: "300px" }} className="bg-primary">
        Loading...
      </div>
    );

  return (
    <div className="mb-3 overflow-auto" style={{ height: "830px", scrollbarWidth: "none" }}>
      {profiles.map((profile) => (
        <Row
          style={{ cursor: "pointer" }}
          key={profile.id}
          className="my-2 border g-0 rounded-start-5 shawdow leaflet-bar"
          onClick={() => navigate(`/dj-profiles/specificDj/${profile.id}`)}
        >
          <Col lg={4} className="px-0">
            <div className="rounded-start-5">
              <img
                src={profile.profile_image_path || PlaceHolderImage}
                alt={profile?.company_name || "----"}
                className="img-fluid object-fit-cover rounded-start-5"
                style={{ height: "300px", width: "100%" }}
              />
            </div>
          </Col>
          <Col lg={8} className="d-flex flex-column justify-content-center px-0">
            <div className="px-3">
              <h5 className="fw-bold mb-2 fs-2">{profile.company_name || "----"}</h5>
              <p className="text-muted fs-3 mb-2 fw-medium">{profile.city || "----"}</p>
              <p className="fs-5 text-secondary" style={{ height: "90px", overflow: "hidden" }}>
                {profile?.uniqueness && profile.uniqueness.length > 240 ? (
                  <>
                    {profile.uniqueness.substring(0, 240)}
                    <span className="text-primary fw-bold">...</span>
                  </>
                ) : (
                  profile?.uniqueness || "----"
                )}
              </p>
              <div className="d-flex justify-content-between">
                <div className="d-flex align-item-center py-1">
                  <p className="fs-5">Starting from: ${profile?.max_packages?.[0]?.min_package_price || 0}</p>
                </div>
                <div>
                  <Button
                    color="primary"
                    className="me-2"
                    onClick={() => navigate(`/dj-profiles/specificDj/${profile.id}`)}
                  >
                    View DJ Profile
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default ListView;
