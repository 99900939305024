import React from "react";
import { Block, BlockTitle, Icon, PreviewCard } from "../../components/Component";
import { Badge, Card, Col, Progress, Row } from "reactstrap";
import Content from "../../layout/content/Content";

const Review = ({ companyData }) => {
  return (
    <div id="review" className="mb-5">
      <Content>
        <BlockTitle page className="mb-4 text-center" tag="h3">
          Reviews
        </BlockTitle>
        <Row className="border h-500px mb-1">
          <Col lg="5" className="justify-center">
            <div className="d-flex gy-3 w-400px my-5">
              <div className="progress-amount me-4">
                <h1 className="title">4.4</h1>
                <ul className="rating">
                  <li>
                    <Icon name="star-fill"></Icon>
                  </li>
                  <li>
                    <Icon name="star-fill"></Icon>
                  </li>
                  <li>
                    <Icon name="star-fill"></Icon>
                  </li>
                  <li>
                    <Icon name="star-half-fill"></Icon>
                  </li>
                  <li>
                    <Icon name="star"></Icon>
                  </li>
                </ul>
                <span className="sub-text mt-1">
                  <Icon name="users-fill"></Icon> 500 Total
                </span>
              </div>
              <div className="rating-progress-bar gy-1 w-100">
                <div className="progress-rating">
                  <div className="progress-rating me-2">5</div>
                  <Progress value={100} color="success" className="progress-lg"></Progress>
                </div>
                <div className="progress-rating">
                  <div className="progress-rating me-2">4</div>
                  <Progress value={80} color="success" className="progress-lg"></Progress>
                </div>
                <div className="progress-rating">
                  <div className="progress-rating me-2">3</div>
                  <Progress value={60} color="success" className="progress-lg"></Progress>
                </div>
                <div className="progress-rating">
                  <div className="progress-rating me-2">2</div>
                  <Progress value={40} color="success" className="progress-lg"></Progress>
                </div>
                <div className="progress-rating">
                  <div className="progress-rating me-2">1</div>
                  <Progress value={20} color="success" className="progress-lg"></Progress>
                </div>
              </div>
            </div>
          </Col>
          <Col
            lg="7"
            className={`${companyData?.reviews.length === 0 ? "d-flex justify-content-center align-items-center" : ""}`}
          >
            {companyData?.reviews.length > 0 ? (
              companyData?.reviews.map((review, index) => (
                <div className="card-inner" key={index}>
                  <div className="d-flex ">
                    <div className={`user-avatar ${index % 2 === 0 ? "bg-purple" : "bg-info"} me-3`}>
                      <span>{review.clientName.charAt(0).toUpperCase()}</span>
                    </div>
                    <div className="fake-class">
                      <h6 className="mt-0 d-flex align-center">
                        <span>{review.clientName}</span>
                      </h6>
                      <p className="text-soft">{review.message}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div>
                <p className="text-muted fs-2 fw-light">No reviews available</p>
              </div>
            )}
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default Review;
