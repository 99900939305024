import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Filter from "./Filter";
import ProfileList from "./ProfileList";
import { useQuery } from "react-query";
import { getAllCompanies, getAllGenre, getAllServices } from "../../http/get/getApi";
import { useLocation, useNavigate, useParams } from "react-router";

const DjProfiles = () => {
  const [budget, setBudget] = useState(null);
  const [dJCompany, setDJCompany] = useState(null);
  const [multiValue, setMultiValue] = useState(null);
  const [selectedServices, setSelectedServices] = useState("");
  const [selectedGeners, setSelectedGeners] = useState("");

  const [view, setView] = useState("");
  const { page } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const initialPage = parseInt(page, 10) || 1;
  const [currentPage, setCurrentPage] = useState(initialPage);
  const profilesPerPageListView = 6;
  const profilesPerPageImageView = 9;
  const profilesPerPage = view === "list" ? profilesPerPageListView : profilesPerPageImageView;
  const { input, city, companiesList } = location.state || {};

  const [mapView, setMapView] = useState("");
  const [serviceData, setServiceData] = useState([]);
  const [genresData, setGenresData] = useState([]);
  const [handleBudget, setHandleBudget] = useState([]);
  // const [sortValue, setSortValue] = useState("  ");
  const [cityData, setCityData] = useState([]);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    navigate(`/dj-profiles/${pageNumber}`, {
      state: { input, city, companiesList: fetchedCompaniesList, currentPage },
    });
  };

  const {
    data: serviceList,
    isLoading: serviceListLoading,
    isError: serviceListError,
  } = useQuery({
    queryKey: ["get-service-list"],
    queryFn: () => getAllServices(),
  });

  useEffect(() => {
    if (serviceList?.data?.Services) {
      const serviceId = serviceList?.data?.Services?.map((service) => service);
      setServiceData(serviceId);
    }
  }, [serviceList]);

  const {
    data: genreList,
    isLoading: genreListLoading,
    isError: genreListError,
  } = useQuery({
    queryKey: ["get-genre-list"],
    queryFn: () => getAllGenre(),
  });

  useEffect(() => {
    if (genreList?.data?.genres) {
      const genreId = genreList?.data?.genres?.map((genre) => genre);
      setGenresData(genreId);
    }
  }, [genreList]);

  const fetchCompanies = () => {
    return getAllCompanies({
      type: profilesPerPage,
      offset: currentPage,
      selectedServices: selectedServices,
      selectedGeners: selectedGeners,
      budget: budget,
      input: input,
      city: cityData,
      id: dJCompany,
      search: multiValue,
      // sortValue: sortValue,
    });
  };

  const {
    data: fetchedCompaniesList,
    isLoading: companiesListLoading,
    isError: companiesListError,
    refetch: refetchCompaniesList,
  } = useQuery(
    [
      "get-fetched-companies",
      currentPage,
      profilesPerPage,
      input,
      city,
      selectedServices,
      selectedGeners,
      budget,
      dJCompany,
      multiValue,
      cityData,
      // sortValue,
    ],
    fetchCompanies,
    {
      enabled: false,
      initialData: companiesList ?? { data: [] },
    }
  );
  // Ensure refetch when these dependencies change
  useEffect(() => {
    refetchCompaniesList();
  }, [selectedServices, selectedGeners, budget, currentPage, dJCompany, multiValue, cityData]);

  return (
    <Row className="gx-0" style={{ height: "950px" }}>
      {mapView === "map" ? (
        ""
      ) : (
        <Col lg={3} className="px-1 py-2 ">
          <Filter
            budget={budget}
            selectedServices={selectedServices}
            selectedGeners={selectedGeners}
            // services={services}
            serviceData={serviceData}
            genresData={genresData}
            // generes={generes}
            handleServiceChange={setSelectedServices}
            handleGenerChange={setSelectedGeners}
            setBudget={setBudget}
            refetchCompaniesList={refetchCompaniesList}
            dJCompany={dJCompany}
            setDJCompany={setDJCompany}
            fetchedCompaniesList={fetchedCompaniesList}
            mapView={mapView}
            setMultiValue={setMultiValue}
            multiValue={multiValue}
            setCityData={setCityData}
          />
        </Col>
      )}

      <Col lg={mapView === "map" ? 12 : 9} className={mapView === "map" ? "px-0 py-0" : "px-3 py-2"}>
        <ProfileList
          budget={budget}
          selectedServices={selectedServices}
          selectedGeners={selectedGeners}
          fetchedCompaniesList={fetchedCompaniesList}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          companiesListLoading={companiesListLoading}
          handlePageChange={handlePageChange}
          profilesPerPage={profilesPerPage}
          dJCompany={dJCompany}
          setMapView={setMapView}
          mapView={mapView}
          multiValue={multiValue}
          // setSortValue={setSortValue}
          // sortValue={sortValue}
        />{" "}
      </Col>
    </Row>
  );
};

export default DjProfiles;
