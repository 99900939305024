import React from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import placeHolderImg from "../../assets/images/placeHolderImg.png";
import { Block, Icon, TooltipComponent } from "../../components/Component";
import Fancybox from "./Fancybox";
import { Link } from "react-router-dom";
import { Card } from "reactstrap";

const ImageCarousel = ({ companyData }) => {
  return (
    <>
      <Block>
        <Card className="border-0 mb-4">
          <div className="d-flex justify-between">
            {/* <BlockTitle>{companyData?.company_name}</BlockTitle> */}
            <div className="w-100">
              <div class="cardCompanyTitle">
                <div className="imageCompanyTitle">
                  <div class="pfpheader">
                    <div class="playingheader ">
                      <div class="greenlineheader line-1audio "></div>
                      <div class="greenlineheader  line-2audio "></div>
                      <div class="greenlineheader  line-3audio "></div>
                      <div class="greenlineheader  line-4audio "></div>
                      <div class="greenlineheader  line-5audio "></div>
                    </div>
                  </div>
                </div>
                <div class="card-infoCompanyTitle">
                  <span>{companyData?.company_name}</span>
                </div>
              </div>
            </div>
            <div>
              {companyData?.website ? (
                <span className="fs-2 mx-1" id="websiteId">
                  <Link to={companyData?.website} target="_blank">
                    <Icon
                      name="monitor"
                      style={{ backgroundColor: "white", color: "#405DE6", padding: "2px" }}
                      className="h-100 border border-1 rounded-3"
                    ></Icon>
                  </Link>
                  <TooltipComponent id={"websiteId"} text="Visit Website" direction="top" />
                </span>
              ) : (
                ""
              )}
              {companyData?.instagram ? (
                <span className="fs-2 mx-1" id="instagramId">
                  <Link to={companyData?.instagram} target="_blank">
                    <Icon
                      name="instagram"
                      style={{ backgroundColor: "white", color: "	#E1306C", padding: "2px" }}
                      className="h-100 border border-1 rounded-3"
                    ></Icon>
                  </Link>
                  <TooltipComponent id={"instagramId"} text="Visit Instagram" direction="top" />
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </Card>
      </Block>
      <div className="d-flex border-1 pb-0 overflow-auto" style={{ height: "70vh" }}>
        <Fancybox
          options={{
            Carousel: {
              infinite: false,
            },
          }}
        >
          {Object.keys(companyData?.images || {}).length > 0 && companyData?.images ? (
            Object.keys(companyData.images).map((category, categoryIdx) =>
              companyData.images[category].length > 0 ? (
                companyData.images[category].map((image, idx) => (
                  <div className="col-4 h-50 justify-center mt-0" key={idx} style={{ padding: "1px 1px" }}>
                    <a
                      data-fancybox="gallery"
                      href={image.url || placeHolderImg}
                      className="justify-center h-100 w-100"
                    >
                      <img
                        alt="DJ Image"
                        src={image.url || placeHolderImg}
                        className="object-fit-cover w-100"
                        // style={{ height: "200px" }}
                      />
                    </a>
                  </div>
                ))
              ) : (
                <div className="align-center justify-center" style={{ height: "50vh" }}>
                  <h3 className="text-muted fs-2 fw-light">No Images Available</h3>
                </div>
              )
            )
          ) : (
            <div className="align-center justify-center" style={{ height: "50vh" }}>
              <h3 className="text-muted fs-2 fw-light">No Images Available</h3>
            </div>
          )}
        </Fancybox>
      </div>
    </>
  );
};

export default ImageCarousel;
