import React from "react";
import { useNavigate } from "react-router";
import { Card, CardBody, CardImg, CardTitle, CardText, Button, Col, Row } from "reactstrap";
import PlaceHolderImage from "../../images/PlaceHolderImg2.jpg";

const ImagesView = ({ profiles }) => {
  const navigate = useNavigate();
  // if (companiesListLoading) return <div className="bg-danger w-200px">Loading...</div>;
  return (
    <div>
      <Row className="g-1 mt-1 overflow-auto" style={{ height: "830px", scrollbarWidth: "none" }}>
        {profiles.map((profile) => (
          <Col
            md={6}
            xl={4}
            key={profile.id}
            className="shadow-sm mb-4"
            onClick={() => navigate(`/dj-profiles/specificDj/${profile.id}`)}
            style={{ cursor: "pointer" }}
          >
            <Card className="shadow-sm h-100">
              <CardImg
                top
                src={profile.profile_image_path || PlaceHolderImage}
                alt={profile.company_name || "----"}
                className="rounded-top "
                style={{ height: "250px", objectFit: "cover" }}
              />
              <CardBody className="p-4 d-flex flex-column justify-content-between">
                <div className="">
                  <CardTitle tag="h5" className="mb-1">
                    {profile.company_name || "----"}
                  </CardTitle>
                  <CardText className="text-muted mb-1">{profile.city || "----"}</CardText>
                  <CardText
                    className="fs-14px text-secondary h-100px mb-1 overflow-auto"
                    style={{ scrollbarWidth: "none" }}
                  >
                    {profile?.uniqueness || "----"}
                  </CardText>
                  <CardText className="mb-0 ">
                    Starting from: ${profile?.max_packages?.[0]?.min_package_price || 0}
                  </CardText>
                </div>
                <div className="mt-3">
                  <Button
                    color="primary"
                    className="me-2 rounded-pill w-100 d-flex justify-content-center"
                    onClick={() => navigate(`/dj-profiles/specificDj/${profile.id}`)}
                  >
                    View Details
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ImagesView;
