import React from "react";
import { BiBookContent } from "react-icons/bi";
import { PiStrategy } from "react-icons/pi";
import { GiUpgrade } from "react-icons/gi";
import { TbDeviceAnalytics } from "react-icons/tb";
import { TbPresentationAnalytics } from "react-icons/tb";
import { MdOutlineCampaign } from "react-icons/md";
import { Button } from "reactstrap";
import { useNavigate } from "react-router";
import { Icon } from "../../components/Component";

const ServiceCards = () => {
  const navigate = useNavigate();
  return (
    <>
      <div class="container my-5">
        <div class="text-center mb-3">
          <div class="fs-4">Our DJ Services</div>
          <div class="fs-2 fw-medium text-primary">Transform Your Event with Our Versatile DJ Services</div>
        </div>
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 py-2">
          <div class="col my-3 justify-center">
            <div class="cardUI px-4 py-3 pb-2">
              <div class="card-detailsUI">
                <div class="d-flex align-center mb-2 text-titleUI">
                  <div class="fs-1 align-center">
                    <Icon name="play-circle"></Icon>
                  </div>
                  <div class="fs-16px mx-1 fw-semibold">Uplighting</div>
                </div>
                <div>
                  Enhance the ambiance of your event with our professional uplighting services. Our customizable
                  lighting solutions create stunning visual effects, highlighting architectural features and setting the
                  perfect mood for any occasion.
                </div>
              </div>
              <button class="card-buttonUI" onClick={() => navigate("/dj-profiles/1")}>
                More info
              </button>
            </div>
          </div>
          <div class="col my-3 justify-center">
            <div class="cardUI px-4 py-3 pb-2">
              <div class="card-detailsUI">
                <div class="d-flex align-center mb-2 text-titleUI">
                  <div class="fs-1 align-center">
                    <Icon name="play-circle"></Icon>
                  </div>
                  <div class="fs-16px mx-1 fw-semibold">Photo Booth</div>
                </div>
                <div>
                  Capture the fun and create lasting memories with our interactive Photo Booth services. Equipped with
                  high-quality cameras, customizable props, and instant prints, our photo booths add an extra layer of
                  entertainment to your event.
                </div>
              </div>
              <button class="card-buttonUI" onClick={() => navigate("/dj-profiles/1")}>
                More info
              </button>
            </div>
          </div>
          <div class="col my-3 justify-center">
            <div class="cardUI px-4 py-3 pb-2">
              <div class="card-detailsUI">
                <div class="d-flex align-center mb-2 text-titleUI">
                  <div class="fs-1 align-center">
                    <Icon name="play-circle"></Icon>
                  </div>
                  <div class="fs-16px mx-1 fw-semibold">Master of Ceremonies</div>
                </div>
                <div>
                  Ensure your event flows smoothly and stays on track with our professional Master of Ceremonies
                  services. Our experienced MCs bring charisma, clear communication, and a polished presence to your
                  event, guiding your audience.
                </div>
              </div>
              <button class="card-buttonUI" onClick={() => navigate("/dj-profiles/1")}>
                More info
              </button>
            </div>
          </div>
          <div class="col my-3 justify-center">
            <div class="cardUI px-4 py-3 pb-2">
              <div class="card-detailsUI">
                <div class="d-flex align-center mb-2 text-titleUI">
                  <div class="fs-1 align-center">
                    <Icon name="play-circle"></Icon>
                  </div>
                  <div class="fs-16px mx-1 fw-semibold">Monogram</div>
                </div>
                <div>
                  Add a personal touch to your event with our custom monogram services. Whether it's your initials,
                  logo, we project your monogram onto walls, ceilings that enhances the ambiance. Our professional-grade
                  lighting ensures monogram is crisp, clear
                </div>
              </div>
              <button class="card-buttonUI" onClick={() => navigate("/dj-profiles/1")}>
                More info
              </button>
            </div>
          </div>
          <div class="col my-3 justify-center">
            <div class="cardUI px-4 py-3 pb-2">
              <div class="card-detailsUI">
                <div class="d-flex align-center mb-2 text-titleUI">
                  <div class="fs-1 align-center">
                    <Icon name="play-circle"></Icon>
                  </div>
                  <div class="fs-16px mx-1 fw-semibold">Audio/Visual</div>
                </div>
                <div>
                  Create an immersive experience with our cutting-edge audio and visual services. We provide
                  high-quality sound systems, dynamic lighting, and stunning visual effects that elevate the atmosphere
                  of any event. Our A/V solutions ensure event looks.
                </div>
              </div>
              <button class="card-buttonUI" onClick={() => navigate("/dj-profiles/1")}>
                More info
              </button>
            </div>
          </div>
          <div class="col my-3 justify-center">
            <div class="cardUI px-4 py-3 pb-2">
              <div class="card-detailsUI">
                <div class="d-flex align-center mb-2 text-titleUI">
                  <div class="fs-1 align-center">
                    <Icon name="play-circle"></Icon>
                  </div>
                  <div class="fs-16px mx-1 fw-semibold">Smoke Foggers</div>
                </div>
                <div>
                  Add an extra layer of excitement to your event with our professional smoke foggers. Perfect for
                  creating dramatic entrances, enhancing dance floor lighting, or adding atmosphere to any occasionour
                  foggers deliver a stunning visual effect.
                </div>
              </div>
              <button class="card-buttonUI" onClick={() => navigate("/dj-profiles/1")}>
                More info
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceCards;
