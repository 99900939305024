import React from "react";
import { Link } from "react-router-dom";
import footerImgDark from "../../images/IPartyDJ_MIX.png";
import { Col, Row } from "reactstrap";
import { BlockTitle, Icon } from "../../components/Component";
import Cookies from "js-cookie";

const Footer = () => {
  const handleCookieSet = (val) => {
    const now = new Date();
    const expires = new Date(now.getTime() + 5 * 1000);
    Cookies.set("serviceId", val, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
  };
  let currentUrl;

  if (window.location.pathname !== undefined) {
    currentUrl = window.location.pathname;
  } else {
    currentUrl = null;
  }
  return (
    <div className="nk-footer p-0">
      <div className="container-fluid">
        <Row className="justify-around m-3">
          <Col lg={3} className="justify-center">
            <div className="d-flex flex-column justify-center">
              <div className="justify-center p-3" style={{ width: "150px" }}>
                {/* <img src={footerImg} alt="DJ Section" className="img-fluid" /> */}
                <Link to="/">
                  <img src={footerImgDark} alt="DJ Section" className="" />
                </Link>
              </div>
              <div className="d-flex justify-center flex-column mt-2">
                <BlockTitle className="fs-4 mx-1 mb-0"> Follow Us On</BlockTitle>
                <div className="d-flex my-2">
                  <span className="mx-1">
                    <Link to="https://www.facebook.com/profile.php?id=100057235275531" target="_blank">
                      <div class="parentUI">
                        <div class="childUI child-1UI">
                          <button class="buttonUI btn-2UI">
                            <Icon name="facebook-fill" style={{ color: "#316FF6" }}></Icon>
                          </button>
                        </div>
                      </div>
                    </Link>
                  </span>
                  <span className="mx-1">
                    <Link to="https://www.instagram.com/ipartydjmix/?hl=en" target="_blank">
                      <div class="parentUI">
                        <div class="childUI child-2UI">
                          <button class="buttonUI btn-2UI">
                            <Icon name="instagram" style={{ color: "#E1306C" }}></Icon>
                          </button>
                        </div>
                      </div>
                    </Link>
                  </span>
                  {/* <span className="fs-2 mx-1">
                    <Link to="#">
                      <Icon name="pinterest" style={{ color: "#E60023" }}></Icon>
                    </Link>
                  </span>
                  <span className="fs-2 mx-1">
                    <Link to="#">
                      <Icon name="youtube-fill" style={{ color: "#FF0000" }}></Icon>
                    </Link>
                  </span>
                  <span className="fs-2 mx-1">
                    <Link to="#">
                      <Icon name="vimeo" style={{ color: "#1AB7EA" }}></Icon>
                    </Link>
                  </span> */}
                </div>
              </div>
            </div>
          </Col>
          <Col lg={9}>
            <div className="row justify-around">
              <div className="col-auto">
                {/* <div> */}
                <BlockTitle className="text-primary">
                  <span style={{ fontSize: "14px" }} className="fw-bold">
                    Company
                  </span>
                </BlockTitle>
                <ul className="" style={{ fontSize: "14px" }}>
                  {/* onClick={() => handleCookieSet(2)} */}
                  <li>
                    <Link
                      to={`${process.env.REACT_APP_PUBLIC_URL}`}
                      className={currentUrl === "/" || currentUrl === "/home" ? "text-primary" : "text-body "}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.REACT_APP_PUBLIC_URL}about`}
                      className={currentUrl === "/about" ? "text-primary" : "text-body "}
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.REACT_APP_PUBLIC_URL}contact`}
                      className={currentUrl === "/contact" ? "text-primary" : "text-body "}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
              {/* </div> */}
              <div className="col-auto">
                <BlockTitle className="text-primary" style={{ fontSize: "14px" }}>
                  <span style={{ fontSize: "14px" }} className="fw-bold">
                    High Profile DJ's
                  </span>
                </BlockTitle>
                <ul className="" style={{ fontSize: "14px" }}>
                  <li>
                    <Link to={`${process.env.REACT_APP_PUBLIC_URL}dj-profiles/specificDj/1101`} className="text-body">
                      Armin Van Buuren
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.REACT_APP_PUBLIC_URL}dj-profiles/specificDj/924`} className="text-body">
                      DJ Snake
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.REACT_APP_PUBLIC_URL}dj-profiles/specificDj/1095`} className="text-body">
                      David Guetta
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.REACT_APP_PUBLIC_URL}dj-profiles/specificDj/984`} className="text-body">
                      DJ Khaled
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.REACT_APP_PUBLIC_URL}dj-profiles/specificDj/926`} className="text-body">
                      The Chainsmokers
                    </Link>
                  </li>{" "}
                  <li>
                    <Link to={`${process.env.REACT_APP_PUBLIC_URL}dj-profiles/specificDj/1102`} className="text-body">
                      Martin Garrix
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-auto">
                <BlockTitle className="text-primary" style={{ fontSize: "14px" }}>
                  <span style={{ fontSize: "14px" }} className="fw-bold">
                    Account
                  </span>
                </BlockTitle>
                <ul className="" style={{ fontSize: "14px" }}>
                  <li>
                    <Link to={`${process.env.REACT_APP_ACCOUNT_URL}register?type=client`} className="text-body">
                      Sign-In as Client
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.REACT_APP_ACCOUNT_URL}register?type=business`} className="text-body">
                      Sign-In as DJ-Company
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="nk-footer-wrap justify-center fs-6 border p-2" style={{ fontSize: "14px" }}>
        <div className="nk-footer-copyright">
          {" "}
          &copy; 2024{" "}
          {process.env.REACT_APP_PUBLIC_URL === "http://localhost:3000/" || "https://ipartydjcrm.com/"
            ? "ipartydjcrm"
            : process.env.REACT_APP_PUBLIC_URL === "http://localhost:3000/" || "https://ipartydjmix.com/"
            ? "ipartydjmix"
            : ""}
          . All rights reserved
        </div>
      </div>
    </div>
  );
};
export default Footer;
