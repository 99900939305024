import React, { useEffect, useState, useCallback, useRef } from "react";
import { Button, DropdownMenu, Input, Label, Spinner } from "reactstrap";
import MultiRangeSlider from "multi-range-slider-react";
import debounce from "lodash.debounce";
import { getAllCities, getAllCount } from "../../http/get/getApi";
import { useQuery } from "react-query";
import "../../css/style.css";
import style from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark";
import Nouislider from "nouislider-react";
import wNumb from "wnumb";
import { Icon } from "../../components/Component";
import Cookies from "js-cookie";

const Filter = ({
  selectedServices,
  selectedGeners,
  serviceData,
  genresData,
  setBudget,
  refetchCompaniesList,
  handleServiceChange,
  handleGenerChange,
  setMultiValue,
  setCityData,
  multiValue,
}) => {
  const [minValue, setMinValue] = useState(100);
  const [maxValue, setMaxValue] = useState(500);
  const [minLable, setMinLable] = useState(100);
  const [maxLable, setMaxLable] = useState(500);
  const [name, setName] = useState("");
  const [sliderKey, setSliderKey] = useState(Date.now());
  const [sliderValues, setSliderValues] = useState([minLable, maxLable]);
  const timeoutRef = useRef();
  const cityDropdownRef = useRef(null);
  const debouncedRefetch = useCallback(debounce(refetchCompaniesList, 1000), [refetchCompaniesList]);
  const [showCitySelect, setShowCitySelect] = useState(false);
  const [citySelected, setCitySelected] = useState("");
  // const [cityData, setCityData] = useState([]);
  const [city, setCity] = useState("");

  const handleInputChange = (e) => {
    setName(e.target.value);

    const value = e.target.value;
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setMultiValue(value);
    }, 1000);
  };

  useEffect(() => {
    debouncedRefetch();
  }, [minValue, maxValue, debouncedRefetch]);

  const handleBudgetChange = useCallback(
    (minVal, maxVal) => {
      setBudget(`${minVal},${maxVal}`);
      debouncedRefetch();
    },
    [setBudget]
  );
  const handleInputFieldChange = useCallback(
    (e, type) => {
      let value = e.target.value ? parseInt(e.target.value) : 0;
      if (value < 0) {
        value = 0;
      } else if (value > 500) {
        value = 500;
      }
      if (type === "min") {
        if (value < maxLable) {
          setMinLable(value);
          handleBudgetChange(value, maxLable);
        } else {
          setMinLable(value);
          handleBudgetChange(maxLable - 1, maxLable);
        }
      } else if (type === "max") {
        if (value > minLable) {
          setMaxLable(value);
          handleBudgetChange(minLable, value);
        } else {
          setMaxLable(value);
          handleBudgetChange(minLable, minLable + 1);
        }
      }
    },
    [minLable, maxLable, handleBudgetChange]
  );

  const handleInput = useCallback(
    (values) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        let [minVal, maxVal] = values.map((value) => parseInt(value));
        if (minVal === maxVal) {
          maxVal = maxVal < 500 ? maxVal + 1 : minVal - 1;
        }
        handleBudgetChange(minVal, maxVal);
        setMinLable(minVal);
        setMaxLable(maxVal);
      }, 1000);
    },
    [handleBudgetChange]
  );

  const handleServiceCheckboxChange = useCallback(
    (serviceId) => {
      handleServiceChange((prevSelectedServices) => {
        const servicesArray = prevSelectedServices || [];
        if (servicesArray.includes(serviceId)) {
          return servicesArray.filter((s) => s !== serviceId);
        } else {
          return [...servicesArray, serviceId];
        }
      });
      debouncedRefetch();
    },
    [handleServiceChange, debouncedRefetch]
  );

  const handleGenereCheckboxChange = useCallback(
    (genereId) => {
      handleGenerChange((prevSelectedGeners) => {
        const generArray = prevSelectedGeners || [];
        if (generArray.includes(genereId)) {
          return generArray.filter((s) => s !== genereId);
        } else {
          return [...generArray, genereId];
        }
      });
      debouncedRefetch();
    },
    [handleGenerChange, debouncedRefetch]
  );

  const clearFilters = () => {
    setName("");
    setBudget(null);
    setMultiValue(null);
    handleServiceChange([]);
    handleGenerChange([]);
    setMinValue(0);
    setMaxValue(500);
    setMaxLable(500);
    setMinLable(0);
    setSliderKey(Date.now());
    setCitySelected("");
    setCityData("");
  };

  const {
    data: listCount,
    isLoading: listCountLoading,
    isError: listCountError,
  } = useQuery({
    queryKey: ["get-list-count"],
    queryFn: () => getAllCount(),
  });
  const handleCityChange = (e) => {
    const value = e.target.value;
    setCitySelected(value);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setCityData(value);
    }, 1000);
  };
  // const {
  //   data: cityList,
  //   isLoading: cityListLoading,
  //   isError: cityListError,
  // } = useQuery({
  //   queryKey: ["get-city-list", cityData],
  //   queryFn: () => getAllCities({ city: cityData }),
  // });

  const handleCitySelect = (name) => {
    const cityNameWithState = `${name?.city_name}, ${name?.state_name}`;
    setCitySelected(cityNameWithState);
    setCity(name?.city_name);
    setShowCitySelect(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cityDropdownRef.current && !cityDropdownRef.current.contains(event.target)) {
        setShowCitySelect(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  let getCity;
  useEffect(() => {
    getCity = Cookies.get("cityData") || "";
    setCitySelected(getCity);
    setCityData(getCity);
    Cookies.remove("cityData");
  }, []);

  return (
    <div>
      <div className="d-flex justify-between mx-3 align-center border-bottom pb-2">
        <div className="align-center">
          <h5 className="font-weight-bold">Filter</h5>
        </div>
        <div className="justify-end">
          <Button color="primary" onClick={clearFilters} className="px-3 w-100 justify-center" id="filter">
            Clear All
          </Button>
        </div>
      </div>

      <div className="p-1">
        <div className="border-bottom pb-2">
          <div className="App my-3 mb-1">
            <Label>Select Search</Label> <span> </span>
            <Input
              size="lg"
              type="text"
              maxLength={50}
              value={name}
              onChange={(e) => {
                handleInputChange(e);
                // setName(e.target.value);
              }}
              className="flex-grow-1 rounded-2"
              placeholder="CompanyName, UserName, Address"
            />
          </div>
          <div className="App my-3 mb-1">
            {/* <Label>Select City</Label> */}
            <Input
              size="lg"
              type="text"
              value={citySelected}
              onClick={() => setShowCitySelect(true)}
              onChange={(e) => {
                handleCityChange(e);
              }}
              className="flex-grow-1 rounded-2"
              placeholder="City"
            />
            {/* {showCitySelect && (
              <div
                ref={cityDropdownRef}
                className="position-absolute h-200px overflow-auto mt-1"
                style={{ width: "360px" }}
              >
                <DropdownMenu className="d-flex flex-wrap p-1 border w-100">
                  {cityListLoading ? (
                    <div className="mx-1 align-center">
                      <Spinner size="sm" color="light" />
                      <span className="px-1">Loading...</span>
                    </div>
                  ) : cityListError ? (
                    <div>Error loading cities. Please try again.</div>
                  ) : cityList?.data?.cities_with_states?.length ? (
                    cityList.data.cities_with_states.map((name, index) => (
                      <div
                        key={name.city_id || index}
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleCitySelect(name)}
                      >
                        {name.city_name}, {name.state_name}
                      </div>
                    ))
                  ) : (
                    <div>No Cities Found</div>
                  )}
                </DropdownMenu>
              </div>
            )} */}
          </div>
          <div className="App my-3">
            <Label>Select Budget</Label>
            {/* <div className="leaflet-bar rounded-5 py-2 px-4">
              <div className="d-flex mx-1 justify-center">
                <div className="lead-text p-0">Filtered Range :</div>
                <div className=" p-0 d-flex">
                  {minLable === "---" && maxLable === "---" ? (
                    <>
                      <div className="mx-1 px-1">No Range Selected</div>
                    </>
                  ) : (
                    <>
                      <div className="mx-1 text-white bg-primary rounded-3 px-1">${minLable}</div>
                      <div className="mx-1 text-white bg-primary rounded-3 px-1">${maxLable}</div>
                    </>
                  )}
                </div>
              </div>
              <MultiRangeSlider
                key={sliderKey} // Add the key prop to force re-render
                className="custom-multi-range-slider shadow-none border-0 pt-3 px-2"
                min={0}
                max={500}
                minValue={minValue}
                maxValue={maxValue}
                ruler={false}
                stepOnly="true"
                onChange={handleInput}
                barInnerColor="#4FBB26"
                // onInput={handleInput}
              />
            </div> */}

            <div className="form-control-wrap py-1 px-4 mb-2">
              <Nouislider
                key={sliderKey}
                className="form-range-slider"
                accessibility
                tooltips={true}
                connect={true}
                step={10}
                keyboardSupport={true}
                // start={[0, 500]}
                start={[minLable, maxLable]}
                range={{
                  min: minValue,
                  max: maxValue,
                }}
                format={wNumb({
                  decimals: 0, // Ensure values are integers
                })}
                onChange={handleInput}
              ></Nouislider>
            </div>
            <div className="d-flex justify-center align-center mx-1">
              <span style={{ width: "90px" }} className="mx-1">
                <div className="form-control-wrap ">
                  <div className="form-icon form-icon-left">
                    <Icon name="sign-dollar" />
                  </div>
                  <Input
                    size="sm"
                    type="number"
                    value={minLable}
                    onChange={(e) => {
                      handleInputFieldChange(e, "min");
                    }}
                    className="flex-grow-1 rounded-2 w-100 pt-1"
                    placeholder="Minimum Value"
                  />
                </div>
              </span>
              <span className="mx-1">to</span>
              <span style={{ width: "90px" }} className="mx-1">
                <div className="form-control-wrap ">
                  <div className="form-icon form-icon-left">
                    <Icon name="sign-dollar" />
                  </div>
                  <Input
                    size="sm"
                    type="number"
                    value={maxLable}
                    onChange={(e) => {
                      handleInputFieldChange(e, "max");
                    }}
                    className="flex-grow-1 rounded-2 w-100 pt-1"
                    placeholder="Maximum Value"
                    // min={minLable}
                    max={500}
                  />
                </div>
              </span>
            </div>
          </div>
        </div>
        <div className="genres my-3 pb-2 border-bottom">
          <Label>Services</Label>
          <div className="h-200px overflow-auto">
            {serviceData.map((service, index) => (
              <div
                key={index}
                className="form-check form-check-lg align-center py-1 bghover"
                style={
                  selectedServices?.includes(service?.id)
                    ? { backgroundColor: "#e6eef5", cursor: "pointer", color: "#4fbb26" }
                    : { cursor: "pointer" }
                }
                onClick={() => handleServiceCheckboxChange(service?.id)}
              >
                <input
                  className="form-check-input border mt-0 align-center ps-2"
                  type="checkbox"
                  name="checkbox"
                  id={`service${index}`}
                  checked={selectedServices?.includes(service?.id) || false}
                  onChange={() => {}}
                  style={{ width: "1.5em", height: "1.5em", marginRight: "1em", marginLeft: "-15px" }}
                />
                <label className="form-check-label ml-2 fs-6 rounded-3">
                  {service?.title}
                  <span>
                    {" "}
                    (
                    {listCount === true
                      ? listCount &&
                        Object.entries(listCount?.data?.service_counts).map(([servicescount, count], index) => (
                          <span key={`service-${index}`}>{servicescount === service?.title ? count : ""}</span>
                        ))
                      : ""}
                    )
                  </span>
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="generes my-3 pb-2 border-bottom">
          <Label>Genres</Label>
          <div className="h-200px overflow-auto">
            {genresData.map((genere, index) => (
              <div
                key={index}
                className="form-check form-check-lg align-center py-1 bghover"
                style={
                  selectedGeners?.includes(genere?.id)
                    ? { backgroundColor: "#e6eef5", cursor: "pointer", color: "#4fbb26" }
                    : { cursor: "pointer" }
                }
                onClick={() => handleGenereCheckboxChange(genere?.id)}
              >
                <input
                  className="form-check-input border mt-0 align-center ps-2"
                  type="checkbox"
                  name="checkbox"
                  id={`genere${index}`}
                  checked={selectedGeners?.includes(genere?.id) || false}
                  onChange={() => {}}
                  style={{ width: "1.5em", height: "1.5em", marginRight: "1em", marginLeft: "-15px" }}
                />
                <label className="form-check-label ml-2 fs-6 rounded-3">
                  {genere?.title}
                  <span>
                    {" "}
                    (
                    {listCount === true
                      ? listCount &&
                        Object.entries(listCount?.data?.genre_counts).map(([generecount, count], index) => (
                          <span key={`genre-${index}`}>{generecount === genere?.title ? count : ""}</span>
                        ))
                      : ""}
                    )
                  </span>
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
