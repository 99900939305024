import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Form, Input, Button, DropdownMenu, Spinner } from "reactstrap";
import DjImage from "../../assets/images/homeBg.jpg";
import { useLocation, useNavigate } from "react-router";
import "./style.module.css";
import { getAllCategories, getAllCities, getAllCompanies } from "../../http/get/getApi";
import { useQuery } from "react-query";
import Cookies from "js-cookie";

const HeroSection = () => {
  const location = useLocation();
  const [input, setInput] = useState("");
  const [fitlerCategory, setFitlerCategory] = useState("");
  const [showEventTypeSelect, setShowEventTypeSelect] = useState(false);
  const [showCitySelect, setShowCitySelect] = useState(false);
  const [city, setCity] = useState("");
  const [cityData, setCityData] = useState([]);
  const [citySelected, setCitySelected] = useState("");
  const navigate = useNavigate();
  const eventTypeDropdownRef = useRef(null);
  const cityDropdownRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [dropDownData, setDropDownData] = useState(false);
  const timeoutRef = useRef();

  // useEffect(() => {
  //   const savedState = localStorage.getItem("searchState");
  //   if (savedState) {
  //     const { input, city } = JSON.parse(savedState);
  //     setInput(input);
  //     setCity(city);
  //   }
  // }, []);

  const {
    data: categoryList,
    isLoading: categoryListLoading,
    isError: categoryListError,
  } = useQuery({
    queryKey: ["get-category-list"],
    queryFn: () => getAllCategories(),
  });

  const {
    data: cityList,
    isLoading: cityListLoading,
    isError: cityListError,
  } = useQuery({
    queryKey: ["get-city-list", cityData],
    queryFn: () => getAllCities({ city: cityData }),
  });
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (eventTypeDropdownRef.current && !eventTypeDropdownRef.current.contains(event.target)) {
        setShowEventTypeSelect(false);
      }
      if (cityDropdownRef.current && !cityDropdownRef.current.contains(event.target)) {
        setShowCitySelect(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputClick = (inputType) => {
    if (inputType === "CategoryType") {
      setShowEventTypeSelect(true);
      setShowCitySelect(false);
    } else if (inputType === "city") {
      setShowCitySelect(true);
      setShowEventTypeSelect(false);
    }
  };

  const handleSelect = (name) => {
    setInput(name.title);
    setFitlerCategory(name.id);
    setShowEventTypeSelect(false);
  };

  const handleCitySelect = (name) => {
    const cityNameWithState = `${name?.city_name}, ${name?.state_name}`;
    setCitySelected(cityNameWithState);
    // setCity(cityNameWithState);
    setCity(name?.city_name);
    setShowCitySelect(false);
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const fetchedCompaniesList = await getAllCompanies({
        type: 10,
        offset: 1,
        category: fitlerCategory,
        city: city,
      });
      setLoading(false);
      navigate("/dj-profiles/1", { state: { companiesList: fetchedCompaniesList, fitlerCategory, city } });
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
      Cookies.set("cityData", city, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const handleCityChange = (e) => {
    const value = e.target.value;
    setCitySelected(value);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setCityData(value);
    }, 1000);
  };
  // console.log(citySelected);

  return (
    <section className="bg-white">
      <Row className="gx-0 border border-bottom ">
        <Col lg={7} className="px-3 py-5">
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}>
            <div className="text-center">
              <h1 className="mb-4 text-center font-weight-bold">
                Your <span style={{ color: "#ff1300" }}>Ultimate</span> DJ Booking Platform
              </h1>
              <p className="mb-4 fs-5">Search hundreds of DJs for weddings, birthday parties, and more.</p>
              <Form className="mb-4">
                <Row className="align-items-end gx-0">
                  <Col md={5}>
                    <Input
                      size="xl"
                      type="text"
                      value={input}
                      className="rounded-start flex-grow-1 rounded-0"
                      placeholder="Find the perfect DJ for your event."
                      onClick={() => handleInputClick("CategoryType")}
                      onChange={(e) => {
                        setInput(e.target.value);
                        setDropDownData(true);
                      }}
                    />
                    {showEventTypeSelect && (
                      <div
                        ref={eventTypeDropdownRef}
                        className="position-absolute h-200px overflow-auto mt-1"
                        style={{ zIndex: 1, width: "350px" }}
                      >
                        <DropdownMenu className="d-flex flex-wrap p-1 border">
                          {categoryListLoading ? (
                            <div className="mx-1 align-center">
                              <Spinner size="sm" color="light" />
                              <span className="px-1">Loading...</span>
                            </div>
                          ) : categoryListError ? (
                            <div>Error loading category. Please try again.</div>
                          ) : categoryList?.data?.Category?.length ? (
                            categoryList?.data?.Category?.map((category, index) => (
                              <div
                                key={index}
                                className="dropdown-item"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleSelect(category)}
                              >
                                {" "}
                                {category.title}
                              </div>
                            ))
                          ) : (
                            <div>No Category Found</div>
                          )}
                        </DropdownMenu>
                      </div>
                    )}
                  </Col>
                  <Col md={5}>
                    <Input
                      size="xl"
                      type="text"
                      value={citySelected}
                      onClick={() => handleInputClick("city")}
                      onChange={handleCityChange}
                      className="flex-grow-1 rounded-0"
                      placeholder="City"
                    />
                    {showCitySelect && (
                      <div
                        ref={cityDropdownRef}
                        className="position-absolute h-200px overflow-auto mt-1"
                        style={{ zIndex: 1, width: "350px" }}
                      >
                        <DropdownMenu className="d-flex flex-wrap p-1 border w-100">
                          {cityListLoading ? (
                            <div className="mx-1 align-center">
                              <Spinner size="sm" color="light" />
                              <span className="px-1">Loading...</span>
                            </div>
                          ) : cityListError ? (
                            <div>Error loading cities. Please try again.</div>
                          ) : cityList?.data?.cities_with_states?.length ? (
                            cityList.data.cities_with_states.map((name, index) => (
                              <div
                                key={name.city_id || index}
                                className="dropdown-item"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleCitySelect(name)}
                              >
                                {name.city_name}, {name.state_name}
                              </div>
                            ))
                          ) : (
                            <div>No Cities Found</div>
                          )}
                        </DropdownMenu>
                      </div>
                    )}
                  </Col>
                  <Col md={2}>
                    <Button
                      // size="xl"
                      color="primary"
                      className="rounded-end flex-grow-1 rounded-0 fs-6"
                      block
                      onClick={handleSearch}
                      disabled={loading}
                      style={{ padding: "10px 5px", height: "55px" }}
                    >
                      {loading ? <Spinner size="sm" color="light" /> : "Search DJs"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </Col>
        <Col
          lg={5}
          className="p-0 container-fluid"
          style={{
            position: "relative",
            overflow: "hidden",
          }}
        >
          <img
            src={DjImage}
            alt="DJ Image"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            className="img-fluid"
          />
        </Col>
      </Row>
    </section>
  );
};

export default HeroSection;
