import React from "react";
import { Block, BlockTitle, Icon, PreviewCard, Row } from "../../components/Component";
import { Button, Card, Col } from "reactstrap";
import DjSectionImg from "../../images/DJ_Section.jpg";
import { useNavigate } from "react-router";

const DjSection = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Block className="border border-1">
        <PreviewCard className="mx-1 my-1 border-0">
          <Row>
            <Col lg="8">
              <BlockTitle className="fs-2 lead-text mb-3">Transform Your DJ Business with I Party DJ MIX</BlockTitle>
              <p>
                Unlock the full potential of your DJ business with I Party DJ MIX, the leading customer relationship
                management platform designed specifically for DJs. Manage your bookings, clients, and events
                seamlessly—All in one place.
              </p>
              <div className="border-1 border-primary leaflet-bar m-3 p-3 shadow">
                <div>
                  <span className="fs-3 lead-text">Why Choose I Party DJ MIX?</span>
                </div>
                <div className="row justify-between px-5">
                  <div className="col-auto">
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">Easy Dashboard</span>
                    </div>
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">Simple Scheduling</span>
                    </div>
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">Quick Payments</span>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">Mobile Access</span>
                    </div>
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">Mobile Management</span>
                    </div>
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">Client & DJ Tools</span>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">Better Client Connections</span>
                    </div>
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">Automated Features</span>
                    </div>
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">Safe and Secure</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="justify-end px-2 my-1">
                <Button className="text-white bg-primary" color="primary" onClick={() => navigate("/dj-section")}>
                  Discover More
                </Button>
              </div>
            </Col>
            <Col lg="4">
              <div className="justify-center h-100">
                <img src={DjSectionImg} alt="DJ Section" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default DjSection;
