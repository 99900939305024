import React, { useState } from "react";
import { Alert, Button, Card, CardBody, CardImg, CardText, CardTitle, Col, Row } from "reactstrap";
import { useNavigate } from "react-router";
import { getCompanyFeaturePerformance } from "../../http/get/getApi";
import { useQuery } from "react-query";
import { ShimmerButton, ShimmerThumbnail } from "react-shimmer-effects";
import placeHolderImg from "../../images/PlaceHolderImg2.jpg";
import { toast } from "react-toastify";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import NoResult from "../../images/No Result.png";

const TopDjs = () => {
  const navigate = useNavigate();

  const {
    data: featuredDJs,
    isLoading: featuredDJsDjsLoading,
    isError: featuredDJsDjsError,
  } = useQuery({
    queryKey: ["get-featuredDJsDjs"],
    queryFn: () => getCompanyFeaturePerformance({ type: 10 }),
  });

  return (
    <>
      <Card className="border mt-0">
        <div className="pt-4 px-4" style={{ backgroundColor: "#f8f9fa" }}>
          <div className="text-center mb-4">
            <h2 className="fs-2 lead-text mb-3">Featured DJ Companies</h2>
            <p className="lead text-muted">
              "These top DJ companies are known for their exceptional talent and professional services, making them the
              best choice for any event. Explore their profiles to find the perfect match for your next celebration "
            </p>
          </div>
          {featuredDJsDjsLoading ? (
            <Row>
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>{" "}
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>{" "}
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>{" "}
            </Row>
          ) : featuredDJsDjsError ? (
            <div className="d-flex flex-column justify-center list-group-item align-center w-100 h-100 my-1 px-2">
              <img src={placeHolderImg} alt={`No Found Image`} className="mb-1" style={{ width: "150px" }} />
              {/* <span className="bi bi-dot fw-medium">{`No ${type} Found`}</span> */}
            </div>
          ) : (
            <Row>
              <div className="mb-4">
                {featuredDJs?.status === false ? (
                  <Col xs="12">
                    <Alert color="white" className="text-center">
                      <h4 className="alert-heading">Oops! Something went wrong.</h4>
                      <p>
                        We're sorry, but we couldn't get the Featured DJs for you at the moment. Please try again later
                        or click the button below to refresh the page and try again.
                      </p>
                      <Button color="primary" onClick={() => window.location.reload()}>
                        Refresh
                      </Button>
                    </Alert>
                  </Col>
                ) : (
                  <Card>
                    {featuredDJs?.data?.Platinum.length < 0 ? (
                      <div className="d-flex justify-center align-center h-100px fs-5">No Data Found</div>
                    ) : (
                      <Carousel
                        additionalTransfrom={0}
                        arrows
                        autoPlaySpeed={3000}
                        centerMode={false}
                        className="p-0 m-0"
                        containerClass=""
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        pauseOnHover
                        renderArrowsWhenDisabled={false}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={{
                          desktop: {
                            breakpoint: {
                              max: 3000,
                              min: 1440,
                            },
                            items: 4,
                            partialVisibilityGutter: 40,
                          },
                          mobile: {
                            breakpoint: {
                              max: 464,
                              min: 0,
                            },
                            items: 1,
                            partialVisibilityGutter: 30,
                          },
                          tablet: {
                            breakpoint: {
                              max: 1024,
                              min: 464,
                            },
                            items: 4,
                            partialVisibilityGutter: 30,
                          },
                        }}
                        rewind={false}
                        rewindWithAnimation={false}
                        rtl={false}
                        shouldResetAutoplay
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={1}
                        // swipeable
                      >
                        {featuredDJs?.data?.Platinum.map((profile) => (
                          <div key={profile.plan.user_id} className="shadow-sm h-100 mx-2">
                            <Card className="h-100">
                              <CardImg
                                top
                                src={profile?.plan?.user_profile?.profile_image_path || placeHolderImg}
                                alt={profile?.plan?.user_profile?.company_name}
                                className="rounded-top"
                                style={{ height: "250px", objectFit: "cover" }}
                              />
                              <CardBody className="p-4 d-flex flex-column justify-content-between">
                                <div>
                                  <CardTitle tag="h5">{profile?.plan?.user_profile?.company_name}</CardTitle>
                                  <CardText className="text-muted">
                                    {profile?.plan?.user_profile?.city || "---"}
                                  </CardText>
                                  <CardText className="mb-3">
                                    Starting from: $ {profile?.plan?.events?.[0]?.event_financial?.package_amount || 0}
                                  </CardText>
                                  <CardText>{profile?.plan?.user_profile?.uniqueness || "---"}</CardText>
                                </div>
                                <div className="mt-3">
                                  <Button
                                    color="primary"
                                    className="me-2 rounded-pill w-100 d-flex justify-content-center align-items-center"
                                    onClick={() => navigate(`/dj-profiles/specificDj/${profile?.plan?.user_id}`)}
                                  >
                                    View Details
                                  </Button>
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        ))}
                      </Carousel>
                    )}
                  </Card>
                )}
              </div>
            </Row>
          )}
        </div>
      </Card>

      <Card className="border mt-0">
        <div className="p-4 px-4" style={{ backgroundColor: "#f8f9fa" }}>
          <div className="text-center mb-4">
            <h2 className="fs-2 lead-text mb-3">Weekly Top Performers</h2>
            <p className="lead text-muted">
              "Analyze the performance of leading DJ companies this week. This report highlights key metrics, trends,
              and insights, providing an overview of how the top players in the DJ industry are performing."
            </p>
          </div>
          {featuredDJsDjsLoading ? (
            <Row>
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>{" "}
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>{" "}
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>{" "}
            </Row>
          ) : featuredDJsDjsError ? (
            <div className="d-flex flex-column justify-center list-group-item align-center w-100 h-100 my-1 px-2">
              <img src={placeHolderImg} alt={`No Found Image`} className="mb-1" style={{ width: "150px" }} />
              {/* <span className="bi bi-dot fw-medium">{`No ${type} Found`}</span> */}
            </div>
          ) : (
            <Row>
              {featuredDJs?.data?.recent_plans.slice(0, 4)?.map((profile) => (
                <Col key={profile.id} xs="12" sm="6" md="4" lg="3" className="mb-4">
                  <Card className="shadow-sm h-100">
                    <CardImg
                      top
                      src={profile?.plan?.user_profile?.profile_image_path || placeHolderImg}
                      alt={profile?.plan?.user_profile?.company_name}
                      className="rounded-top"
                      style={{ height: "250px", objectFit: "cover" }}
                    />
                    <CardBody className="p-4 d-flex flex-column justify-content-between">
                      <div>
                        <CardTitle tag="h5">{profile?.plan?.user_profile?.company_name}</CardTitle>
                        <CardText className="text-muted">{profile?.plan?.user_profile?.city || "---"}</CardText>
                        <CardText className="mb-3">
                          Starting from: $ {profile?.plan?.events?.[0]?.event_financial?.package_amount || 0}
                        </CardText>
                        <CardText>{profile?.plan?.user_profile?.uniqueness || "---"}</CardText>
                      </div>
                      <div className="mt-3">
                        <Button
                          color="primary"
                          className="me-2 rounded-pill w-100 d-flex justify-content-center align-items-center"
                          onClick={() => navigate(`/dj-profiles/specificDj/${profile?.plan?.user_id}`)}
                        >
                          View Details
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
              {featuredDJs?.status === false && (
                <Col xs="12">
                  <Alert color="white" className="text-center">
                    <h4 className="alert-heading">Oops! Something went wrong.</h4>
                    <p>
                      We're sorry, but we couldn't get the Weekly Top Performers DJs for you at the moment. Please try
                      again later or click the button below to refresh the page and try again.
                    </p>
                    <Button color="primary" onClick={() => window.location.reload()}>
                      Refresh
                    </Button>
                  </Alert>
                </Col>
              )}
            </Row>
          )}
        </div>
      </Card>
    </>
  );
};

export default TopDjs;
