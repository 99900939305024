import React, { useState } from "react";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import UserAvatar from "../../../../components/user/UserAvatar";
import { useTheme, useThemeUpdate } from "../../../provider/Theme";
import { getCookie, getInitiatals } from "../../../../utils/Utils";
import Cookies from "js-cookie";

const User = () => {
  const [profileName, setProfileName] = useState(getCookie("username", "John"));
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen((prevState) => !prevState);
  };
  const setLogout = () => {
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);

    Cookies.set("logoutURL", process.env.REACT_APP_PUBLIC_URL, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
    const logoutUrl = `${process.env.REACT_APP_ACCOUNT_URL}logout`;
    window.location.href = logoutUrl;
  };
  const chkDJcompany = Cookies.get("role");
  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <UserAvatar icon="user-alt" className="sm" />
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter ">
          <div className="user-card sm">
            <div className="user-info px-2">
              <UserAvatar text={getInitiatals(profileName)} theme="primary" />
            </div>
            <div className="user-info">
              <span className="lead-text">{getCookie("username", "John")}</span>
              <span className="sub-text">{getCookie("email", "mailto:john@silocloud.io")}</span>
            </div>
          </div>
        </div>
        {chkDJcompany === "3" ? (
          <div className="dropdown-inner">
            <LinkList>
              <LinkItem link={`${process.env.REACT_APP_CLIENT_URL}`} icon="dashboard" target="_blank">
                Client Dashboard
              </LinkItem>
            </LinkList>
          </div>
        ) : chkDJcompany == "2" ? (
          <div className="dropdown-inner">
            <LinkList>
              <LinkItem link={`${process.env.REACT_APP_DASHBOARD_URL}`} icon="dashboard" target="_blank">
                DJ Dashboard
              </LinkItem>
            </LinkList>
          </div>
        ) : (
          ""
        )}
        <div className="dropdown-inner">
          <LinkList>
            <LinkItem link="#" icon="signout" onClick={setLogout}>
              Sign Out
            </LinkItem>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
