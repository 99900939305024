import React from "react";
import { Outlet } from "react-router-dom";
import AppMain from "./global/AppMain";
import AppRoot from "./global/AppRoot";
import AppHeader from "./header/AppHeader";
import Head from "./head/Head";
import Footer from "./footer/Footer";

const Layout = ({ title, app }) => {
  return (
    <>
      <Head title={title || "Loading..."} />
      <AppRoot>
        <AppMain>
          <AppHeader app={app} />
          <Outlet />
          <Footer />
        </AppMain>
      </AppRoot>
    </>
  );
};

export default Layout;
